.projectDetailsRight {
    margin-left: 10rem;
}

@media only screen and (max-width: 900px) {
    .projectDetailBox {
        flex-direction: column;
    }
    .projectDetailsRight {
        margin-left: 0;
    }
}