.proejectFinalProductContainer {
    margin-top: 20rem;
}

.projectFinalProductDemoContainer {
    margin-bottom: 20rem;
}

.projectFinalProductDemoTextBox {
    margin-right: 10rem;
    width: 20rem;
}

.projectFinalProductDemoBox {
    width: fit-content;
    text-align: center;
}

.HostFamMobileDemo video {
    width: inherit;
    max-width: 400px;
}

.projectFinalProductTitle {
    text-align: center;
    margin-bottom: 10rem!important;
}

@media only screen and (max-width: 900px) {
    .proejectFinalProductContainer {
        margin-top: 10rem;
    }
    .projectFinalProductTitle {
        margin-bottom: 5rem!important;
    }
    .projectFinalProductDemoContainer {
        flex-direction: column;
        margin-bottom: 10rem;
    }
    .projectFinalProductDemoTextBox {
        margin-right: 0;
        width: 100%;
        text-align: center;
    }
    .projectFinalProductDemoBox {
        width: 100%;
    }
    .HostFamMobileDemo video {
        max-width: 400px;
    }
}