.aboutIntroContainer {
    flex-direction: row;
}
.aboutImageContainer {
    /* width: fit-content!important; */
    width: 100%!important;
    margin: 0!important;
    max-width: 600px!important;
}
.aboutImage {
    max-width: 600px;
}
.aboutInfoContainer {
    margin: 0!important;
}

@media only screen and (max-width: 1200px) {
    .aboutIntroContainer {
        flex-direction: column;
    }
    .aboutImageContainer {
        /* width: inherit!important; */
        margin-bottom: 3rem!important;
        text-align: center; /*remove this with a better resolution image*/
        max-width: unset!important;
    }
}

@media only screen and (max-width: 900px) {
    .aboutTitle {
        font-size: 3rem!important;
    }

    .aboutImage {
        max-width: unset;
    }
}