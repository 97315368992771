.foodImage {
    opacity: 0.5;
    width: 24%;
    margin: 5px;
    flex: 1 0 24%;
}
.foodImage:hover {
    opacity: 1;
}

@media only screen and (max-width: 1400px) {
    .foodImage {
        width: 32%;
        flex: 1 0 32%;
    }
}

@media only screen and (max-width: 900px) {
    .foodImage {
        opacity: 1;
        margin: 0;
        margin-bottom: 5px;
        width: 100%;
        max-width: unset;
    }
}