.contactContainer ::selection {
    background: #fbfbfb;
    color: var(--brown);
}


@media only screen and (max-width: 900px) {
    .contactText {
        font-size: 2.5rem!important;
    }

}