.experienceDetailBox {
    align-items: flex-end;
}
.experiencePosition {
    margin-right: 1rem!important;
}
@media only screen and (max-width: 900px) {
    .experienceDetailBox {
        flex-direction: column;
        align-items: flex-start;
    }
    .experiencePosition {
        margin-bottom: 1rem!important;
        margin-right: 0!important;
    }
}