.navbarMenuItem:hover {
    color: var(--brown); 
}

.navbarMenuItemColored {
    color: var(--brown)!important;
}

.sideMenuListButton:hover {
    color: var(--brown);
}

.sideMenuListItemTextUnderline {
    text-decoration: underline;
    text-underline-offset: 6px;
}

@media only screen and (max-width: 900px) {
    .navbarMenu {
        display: none;
    }
}

@media only screen and (min-width: 900px) {
    .sidebarIconButton {
        display: none!important;
    }
    .navbarLogoImg {
        height: 3rem!important;
        width: 3rem!important;
    }
    .navbarLogoText {
        font-size: 2rem!important;
        line-height: 3rem!important;
    }
}