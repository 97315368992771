.projectIntroImageContainer {
    margin-left: -24px!important;
    margin-right: -24px!important;
}

.introImgBackgroundHostFam {
    background-color: #f6b19d;
}
.introImgBackgroundSHSI {
    background-color: #6699cc;
}
.introImgBackgroundKimsFlights {
  background-color: #89F0D5;
}
.introImgBackgroundStockSimulator {
  background-color: #5382A1;
}
.projectIntroImageBox {
    flex-direction: row!important;
}

.projectIntroImage {
    width: inherit;
}

@media only screen and (max-width: 600px) {
    .projectIntroImageContainer {
        margin-left: -1rem!important;
        margin-right: -1rem!important;
    }
}