.projectDesignContentWireframe {
    margin-left: -3rem;
    margin-right: -3rem;
    padding: 3rem;
}

@media only screen and (max-width: 600px) {
    .projectDesignContentWireframe {
        margin-left: -2rem;
        margin-right: -2rem;
        padding: 2rem;
    }
}