.introContainer {
    margin-bottom: 10rem;
}

@media only screen and (max-width: 900px) {
    .introContainer {
        margin-bottom: 10rem;
    }
    .introTitle {
        font-size: 1.5rem!important;
    }
    .introTitleName {
        font-size: 3.5rem!important;
    }
    .introTitleDetail {
        font-size: 1.5rem!important;
    }
}
