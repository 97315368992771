.mobileDemoFrame {
    width: 400px;
}

.mobileDemoBox video {
    position: absolute;
    left: 29px;
    top: 90px;
    width: 343px;
}

.studentDesignDetailBox {
    margin-left: 4rem;
}

.laptopDemoFrame {
    width: inherit;
    max-width: 1000px;
}

.youtubePlayerContainer iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

@media only screen and (max-width: 900px) {
    .mobileDemoContainer {
        justify-content: center;
    }
    .mobileDemoFrame {
        width: 250px;
    }
    .mobileDemoBox video {
        width: 215px;
        left: 18px;
        top: 58px;
    }
    .studentDesignDetailBox {
        margin-left: 0;
        margin-top: 4rem;
    }
    .studentDesignBox {
        flex-direction: column;
    }
    
}

@media only screen and (max-width: 1300px) {
    .facultyDesignDetailBox {
        margin-left: 0;
        margin-top: 4rem;
    }
    .facultyDesignBox {
        flex-direction: column;
    }
}