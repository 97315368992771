.proejctGrid {
    grid-template-columns: 50% 50%;
    justify-items: center;
    max-width: none!important;
    /* margin: 0px; */
}
.projectBox {
    margin: 1rem;
    margin-bottom: 2rem;
    width: -webkit-fill-available;
    max-width: 900px;
}
.projectCard:hover {
    cursor: pointer;
}

@media only screen and (max-width: 900px) {
    .proejctGrid {
        grid-template-columns: 100%;
    }
    .projectBox {
        margin: 0;
        margin-bottom: 4rem;
    }
    .projectDescription, .projectKeywordBox {
        color: #555555;
    }
}
